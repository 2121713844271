html {
  background: #F6F5F5 ;
}

a.brand:visited {
  color: #6b5778 ;
  text-decoration-color: #6b5778;
}

img.feature {
  border-width: 4px;
  border-color: #F6F5F5;
  border-style: solid;
}

img.feature:hover {
  border-width: 4px;
  border-color: #6b5778 ;
  border-style: solid ;
}